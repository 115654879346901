import React from 'react'
import './Coverimage.css'
import coverimg from './coverimg.jpg'
function Coverimage() {
  return (
    <div>
      <div className="imagecontainer">
        <div className='writtencontent'>
            <h2> Real Estate company</h2>
            <h1> Commercial Properties  in Noida</h1>
        <p>Satisfying customer needs through a selection of diverse real estate options.</p>


        </div>
        
        
      </div>
    </div>
  )
}

export default Coverimage
